import React from "react"
import Navbar from "./components/navbar"
import BackgroundImage from "gatsby-background-image"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import "./index.scss"

import Slider from "react-slick"

export const query = graphql`
  query {
    allImageSharp {
      edges {
        node {
          fluid {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

const Index = ({ data }) => {
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  // console.log(data.allImageSharp.edges[0].node.fluid.originalName)

  const foto1 = data.allImageSharp.edges.filter(node => {
    return node.node.fluid.originalName === "franquicias-foto.jpg"
  })

  /* const foto2 = data.allImageSharp.edges.filter(node => {
    return node.node.fluid.originalName === "001.jpg"
  }) */

  const foto3 = data.allImageSharp.edges.filter(node => {
    return node.node.fluid.originalName === "002.jpg"
  })

  const foto4 = data.allImageSharp.edges.filter(node => {
    return node.node.fluid.originalName === "003.jpg"
  })

  const foto5 = data.allImageSharp.edges.filter(node => {
    return node.node.fluid.originalName === "004.jpg"
  })

  const foto6 = data.allImageSharp.edges.filter(node => {
    return node.node.fluid.originalName === "005.jpg"
  })

  const logo = data.allImageSharp.edges.filter(node => {
    return node.node.fluid.originalName === "logo.png"
  })

  // console.log(foto1)
  // console.log(foto2)
  // console.log(logo)

  return (
    <div>
      <Helmet>
        <html lang="es" />
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Mostaza es una empresa fundada por profesionales argentinos de excelente trayectoria. Actualmente la marca cuenta con un sólido posicionamiento y con más de 110 sucursales distribuidas en las plazas más importantes del país."
        />
        <meta
          name="keywords"
          content="Las franquicias,Franquicia Mostaza,Venta de negocios,Negocios rentables,Franquicias,Franquicias en Argentina,Emprendimientos rentables,Franquicias 2016,Qué es una franquicia,Oportunidades de negocios,Franquicias emprendedores,Costo de franquicia,Franquicias disponibles,Franquicias costos"
        />
        <title>Mostaza | Franquicias</title>
        <link rel="canonical" href="https://franquicias.mostazaweb.com.ar/" />
        <script
          type="text/javascript"
          defer
          async
          id="lightning_bolt"
          src="//cdn-akamai.mookie1.com/LB/LightningBolt.js"
        ></script>
      </Helmet>

      <Navbar />
      <div className="floating-logo">
        {" "}
        <Img fluid={logo[0].node.fluid} alt="" />
      </div>

      <div className="floating-contact">
        <div className="top">
          <a href="#container2">
            <img alt="envelope" className="envelope" src="/svg/envelope.svg" />{" "}
            <div>CONTACTANOS</div>
          </a>
        </div>
      </div>

      <div className="main-container">
        <BackgroundImage
          Tag="section"
          className="main-image"
          fluid={foto1[0].node.fluid}
          backgroundColor={`#040e18`}
        >
          <section id="container1">
            <h1 className="main-title">FRANQUICIAS</h1>
            <p className="main-subtitle">
              Mostaza es una empresa fundada por profesionales argentinos de
              excelente trayectoria. Actualmente la marca cuenta con un sólido
              posicionamiento y con más de 140 sucursales distribuidas en las
              plazas más importantes del país.
            </p>
          </section>
        </BackgroundImage>
        <section id="container2" className="section-2">
          <div className="text-container">
            <h1 className="main-title">FRANQUICIAS MOSTAZA</h1>
            <p className="main-subtitle">
              Completa este formulario para saber más sobre Franquicias
            </p>
          </div>

          <div className="form-container">
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              action="/success"
            >
              <input type="hidden" name="form-name" value="contact" />
              <input name="bot-field" style={{ display: "none" }} />
              <input
                name="subject"
                type="hidden"
                value="Contacto Franquicia - Mostaza Argentina"
              />
              <input
                aria-label="Nombre"
                type="text"
                name="nombre"
                id="nombre-input"
                placeholder="Nombre *"
                required
              />
              <input
                aria-label="Apellido"
                type="text"
                name="apellido"
                id="apellido-input"
                placeholder="Apellido *"
                required
              />
              <input
                aria-label="Email"
                type="email"
                name="email"
                id="email-input"
                placeholder="Email *"
                required
              />
              <input
                aria-label="Teléfono"
                type="text"
                name="telefono"
                id="telefono-input"
                placeholder="Telefono *"
                required
              />
              <select
                name="como"
                id="como"
                aria-label="Como nos conocieron?"
                required
              >
                <option value="">--Como nos conocieron?--</option>
                <option value="publicidad">Publicidad</option>
                <option value="ticket local">Ticket Local</option>
                <option value="nota">Nota</option>
                <option value="referido">Referido</option>
                <option value="Exposicion">Exposición</option>
                <option value="Web">Web</option>
              </select>
              <input
                type="text"
                name="zona"
                aria-label="Zona de Interés"
                id="zona-input"
                placeholder="Zona de interes *"
                required
              />
              <textarea
                type="text"
                name="mensaje"
                id="mensaje-input"
                aria-label="Mensaje"
                placeholder="Mensaje*"
                required
              />
              <input
                type="text"
                name="ocupacion"
                id="ocupacion-input"
                aria-label="Ocupación"
                placeholder="Ocupacion *"
                required
              />

              <button type="submit">ENVIAR</button>
            </form>
          </div>
        </section>
        <section id="container3">
          <div className="div-container">
            <div>
              <h2>TRAYECTORIA</h2>
              <p>
                Cuenta con más de 20 años de experiencia en el negocio
                gastronómico de comidas rápidas y más de 140 sucursales, por lo
                cual conoce a sus actuales y potenciales consumidores, lo que
                garantiza un negocio exitosamente aceptado y probado.
              </p>
            </div>
            <div>
              <h2>OBRA Y DISEÑO DEL LOCAL</h2>
              <p>
                Mostaza cuenta con especialistas en el tema que lo asesoran en
                todo el proceso de diseño y reformas del local.
              </p>
            </div>
            <div>
              <h2>RENDIMIENTO DE INVERSIÓN</h2>
              <p>
                Los altos niveles de venta con bajos costos permiten obtener una
                mayor contribución marginal. El rendimiento de nuestra unidad de
                negocios supera ampliamente la media del mercado.
              </p>
            </div>
            <div>
              <h2>RENDIMIENTO DE INVERSIÓN</h2>
              <p>
                Los altos niveles de venta con bajos costos permiten obtener una
                mayor contribución marginal. El rendimiento de nuestra unidad de
                negocios supera ampliamente la media del mercado.
              </p>
            </div>
            <div>
              <h2>POOL DE COMPRAS</h2>
              <p>
                Reducción de costos de mercadería derivado del aprovechamiento
                de economías de escala.
              </p>
              <p>
                Para Mostaza un franquiciado es una persona muy importante ya
                que construye la clave del éxito del negocio. Por eso para poder
                lograr juntos los objetivos perseguidos, establece una relación
                directa y dinámica con cada uno de ellos, buscando que se
                identifique con la marca, la propuesta gastronómica y el formato
                de negocio que la empresa ha diseñado.
              </p>
            </div>
            <div>
              <h2>CAPACITACIÓN CONSTANTE </h2>
              <p>
                Mostaza cuenta con un staff integrado por profesionales en
                distintas áreas lo cual le permite brindar al franquiciado una
                capacitación integral y un asesoramiento en forma constante.
              </p>
            </div>
          </div>
        </section>
        <section id="container4">
          <div className="title">
            <h1>FICHA TÉCNICA</h1>
          </div>
          <div className="ficha-tecnica">
            <div className="key">
              <p>Derecho de la franquicia:</p>
              <p>Tamaño del local:</p>
              <p>Inversión total estimada:</p>
              <p>Empleados:</p>
              <p>Regalias:</p>
              <p>Canon de Publicidad:</p>
              <p>Recupero de la inversión:</p>
              <p>Formatos disponibles:</p>
              <p>Participación de Franquiciado:</p>
              <p>Población mínima:</p>
            </div>
            <div className="break"></div>
            <div className="value">
              <p>U$S: 60.000</p>
              <p>desde 70 m2 shopping / desde 170 m2 vía pública</p>
              <p>desde U$S 250.000</p>
              <p>desde 7/20 según formato</p>
              <p>6%</p>
              <p>3,5%</p>
              <p>de 24 a 28 meses</p>
              <p>shopping / express / full</p>
              <p>semi-activa</p>
              <p>70.000 habitantes</p>
            </div>
          </div>
        </section>
        <Slider className="carousel" {...settings1}>
          <div>
            <Img fluid={foto3[0].node.fluid} />
          </div>
          <div>
            <Img fluid={foto4[0].node.fluid} />
          </div>

          <div>
            <Img fluid={foto5[0].node.fluid} />
          </div>

          <div>
            <Img fluid={foto6[0].node.fluid} />
          </div>
        </Slider>

        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </div>
    </div>
  )
}

export default Index
